import React from 'react';

const CompanyLogo = ({ company }) => {
  const logoMap = {
    LockupFixing: "/fixing-3.png",
    MelbTruss: "/logo3.png",
    PromoDoors: "/promodoors.png",
  };

  const logoSrc = logoMap[company]; // Fallback to a default logo if company doesn't match

  if (!logoSrc) return null

  return (
    <div className='login-logo'>
    <img
      src={process.env.PUBLIC_URL + logoSrc}
      alt={`${company} logo`}
    />
    </div>
  );
};

export default CompanyLogo;
