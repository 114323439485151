import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

import "../css/Login.css";
import { CompanyContext } from "./CompanyContext";
import CompanyLogo from "./CompanyLogo";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [userType, setUserType] = useState("");
  const { company, setCompany } = useContext(CompanyContext)
  // const [company, setCompany] = useState(""); // Add state for company selection
  const navigate = useNavigate();


  useEffect(() => {
    const token = Cookies.get("token");

    if (token) {
      axios
        .get(`${process.env[`REACT_APP_R_BASE_SERVER_URL_${company.toUpperCase()}`]}/verify-token`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          if (response.data.valid) {
            navigate(`/${company.toLowerCase()}/${response.data.userType}`); // Navigate to respective dashboard
          }
        })
        .catch(() => {
          Cookies.remove("token");
          Cookies.remove("company");
          setCompany("")
        });
    }
  }, [navigate]);

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        `${process.env[`REACT_APP_R_BASE_SERVER_URL_${company.toUpperCase()}`]}/${userType}/login`,
        { username, password, company } // Include company in the login request
      );
      Cookies.set("token", response.data.token, {
        // secure: true,
        // sameSite: "strict",
      });

      navigate(`/${company.toLowerCase()}/${userType}`);
    } catch (error) {
      console.log(error)
      alert("Invalid username or password");
    }
  };

  const handleButton = async (e) => {
    e.preventDefault();
    document.getElementById("outer-login").classList.add("slide-up");
    setUserType(e.target.id);
  };

  const abortSelection = async (e) => {
    e.preventDefault();
    document.getElementById("outer-login").classList.remove("slide-up");
    document.getElementById("outer-login-company").classList.remove("slide-up");
    setUserType("");
    setCompany("");
    Cookies.remove("imgrf")
  };

  const handleCompanySelection = (selectedCompany) => {
    document.getElementById("outer-login-company").classList.add("slide-up");
    setCompany(selectedCompany); // Set the selected company in the context
  };

  return (
    <div>
      <CompanyLogo company={company} />
      <div className="login-container">
        <div className="outer-login outer-login-company flex flexColumn" id="outer-login-company">
          <button
            onClick={() => {
              handleCompanySelection("MelbTruss")
            }}
            id="melbtruss"
            className="login-select-button flex flexColumn"
          >
            <img
              className="login-select-img login-select-melb"
              src={process.env.PUBLIC_URL + "/melbtruss-logo.png"}
              alt=""
            />

          </button>
          <button
            onClick={() => {
              handleCompanySelection("LockupFixing")
            }}
            id="lockupfixing"
            className="login-select-button flex flexColumn"
          >
            <img
              className="login-select-img login-select-lockup"
              src={process.env.PUBLIC_URL + "/fixing-3.png"}
              alt=""
            />

          </button>
          <button
            onClick={() => {
              handleCompanySelection("PromoDoors")
            }}
            id="promodoors"
            className="login-select-button flex flexColumn"
          >
            <img
              className="login-select-img login-select-lockup promodoors-first-img"
              src={process.env.PUBLIC_URL + "/promodoors.png"}
              alt=""
            />

          </button>
        </div>

        {company === "PromoDoors" ?
          <div className="outer-login flex flexColumn" id="outer-login">
            <button
              onClick={handleButton}
              id="admin"
              className="login-select-button login-select-button-1 flex flexColumn inner-login-select-button"
            >
              <img
                className="login-select-img"
                src={process.env.PUBLIC_URL + "/usericon.png"}
                alt=""
              />
              <p className="login-select-text">ADMIN</p>
            </button>
            <button
              onClick={handleButton}
              id="producer"
              className="login-select-button flex flexColumn inner-login-select-button"
            >
              <img
                className="login-select-img"
                src={process.env.PUBLIC_URL + "/producticon.png"}
                alt=""
              />
              <p className="login-select-text">PRODUCTION</p>
            </button>
            <button
              onClick={handleButton}
              id="driver"
              className="login-select-button flex flexColumn inner-login-select-button"
            >
              <img
                className="login-select-img"
                src={process.env.PUBLIC_URL + "/truckicon.png"}
                alt=""
              />
              <p className="login-select-text">DRIVER</p>
            </button>
          </div>
          :
          <div className="outer-login flex flexColumn" id="outer-login">
            <button
              onClick={handleButton}
              id="admin"
              className="login-select-button login-select-button-1 flex flexColumn inner-login-select-button"
            >
              <img
                className="login-select-img"
                src={process.env.PUBLIC_URL + "/usericon.png"}
                alt=""
              />
              <p className="login-select-text">ADMIN</p>
            </button>
            <button
              onClick={handleButton}
              id="driver"
              className="login-select-button flex flexColumn inner-login-select-button"
            >
              <img
                className="login-select-img"
                src={process.env.PUBLIC_URL + "/truckicon.png"}
                alt=""
              />
              <p className="login-select-text">DRIVER</p>
            </button>
          </div>
        }

        <button className="login-dropdown" onClick={abortSelection}>
          <img src={process.env.PUBLIC_URL + "/dropdown.png"} alt="" />
        </button>
        <h2 className="title login-title">
          {userType == "admin" ? "ADMIN" : userType == "driver" ? "DRIVER" : ""}{" "}
          LOGIN
        </h2>
        <form onSubmit={handleLogin}>
          <div className="flex flexColumn">
            <p className="form-label">Username</p>
            <input
              className="form-input"
              placeholder="Admin"
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </div>
          <div className="flex flexColumn">
            <p className="form-label">Password</p>
            <input
              className="form-input"
              placeholder="*******"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button className="form-button" type="submit">
            Login
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;