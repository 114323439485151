
const routesConfig = {
    lockupfixing: [
        // ADMIN ROUTES 
        { path: "/admin", component: "AdminPanel" },
        { path: "/admin/search", component: "SearchBar" },
        { path: "/admin/assign", component: "AssignPage" },
        { path: "/admin/track", component: "TrackShipments" },
        { path: "/admin/track/:uniqueId", component: "TrackDelivery" },
        { path: "/admin/adddriver", component: "AddDriver" },
        { path: "/admin/removedriver", component: "RemoveDriver" },
        // USER ROUTES
        { path: "/delivery/:uniqueId", component: "UserPage" },
        // DRIVER ROUTES
        { path: "/driver", component: "DriverPanel" },
        { path: "/driver/assignments", component: "DriverAssignments" },
        { path: "/driver/completed", component: "DriverCompleted" },
        { path: "/driver/assignments/:uniqueId", component: "AssignmentDelivery" },
    ],
    melbtruss: [
        // ADMIN ROUTES 
        { path: "/admin", component: "AdminPanel" },
        { path: "/admin/search", component: "SearchBar" },
        { path: "/admin/assign", component: "AssignPage" },
        { path: "/admin/track", component: "TrackShipments" },
        { path: "/admin/track/:uniqueId", component: "TrackDelivery" },
        { path: "/admin/adddriver", component: "AddDriver" },
        { path: "/admin/removedriver", component: "RemoveDriver" },
        // USER ROUTES
        { path: "/delivery/:uniqueId", component: "UserPage" },
        // DRIVER ROUTES
        { path: "/driver", component: "DriverPanel" },
        { path: "/driver/assignments", component: "DriverAssignments" },
        { path: "/driver/completed", component: "DriverCompleted" },
        { path: "/driver/assignments/:uniqueId", component: "AssignmentDelivery" },
    ],
    promodoors: [
        { path: "/admin", component: "Admin" },
        { path: "/admin/assign", component: "Assign" },
        { path: "/admin/track", component: "TrackProduction" },
        { path: "/admin/track/:uniqueId", component: "Assignment" },
        { path: "/admin/producers", component: "Producers" },
        { path: "/admin/drivers", component: "Drivers" },

        { path: "/producer", component: "Producer" },
        { path: "/producer/assign", component: "AssignProducer" },
        { path: "/producer/assignments", component: "ProductionAssignments" },
        { path: "/producer/assignments/:uniqueId", component: "AssignmentProducer" },
        { path: "/producer/track", component: "TrackProductionProducer" },
        { path: "/producer/track/:uniqueId", component: "AssignmentProducer" },
        
        { path: "/driver", component: "Driver" },
        { path: "/driver/assignments", component: "DriverAssignments" },
        { path: "/driver/completed", component: "DriverCompleted" },
        { path: "/driver/assignments/:uniqueId", component: "AssignmentDelivery" },
    ],
};

export default routesConfig;
