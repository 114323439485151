import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Routes, useParams, Navigate } from "react-router-dom";
import routesConfig from "./routesConfig";

import Login from "./components/Login";
import Loading from "./components/Loading";
import ECGMEDIA from "./components/ECGMEDIA";
import Lines from "./components/Lines";

import { LoadingProvider } from "./components/LoadingContext";
import { CompanyProvider } from "./components/CompanyContext";

// import HubSpotScriptManager from './components/HubSpotScriptManager';

import "./App.css";
import "./css/fonts.css";
import "./css/sizes.css";

const DynamicComponentLoader = ({ company, component }) => {
  const Component = lazy(() => import(`./pages/${company}/${component}`));
  return (
    <Suspense fallback={<Loading />}>
      <Component />
    </Suspense>
  );
};

const CompanyRoutes = () => {
  const { company } = useParams();
  const companyRoutes = routesConfig[company] || null;

  if (!companyRoutes) {
    // Redirect to login if the company is invalid
    return <Navigate to="/login" replace />;
  }

  return (
    <Routes>
      {companyRoutes.map((route, index) => (
        <Route
          key={index}
          path={route.path.replace(/^\//, '')} // Remove leading slash for matching
          element={<DynamicComponentLoader company={company} component={route.component} />}
        />
      ))}
      {/* Fallback for unmatched paths within a valid company */}
      <Route path="*" element={<Navigate to="/login" replace />} />
    </Routes>
  );
};


const App = () => (
  <CompanyProvider>
    <LoadingProvider>
      <Router>
        <Loading />
        <ECGMEDIA />
        <Lines />
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path=":company/*" element={<CompanyRoutes />} />
          <Route path="*" element={<Login />} />
        </Routes>
      </Router>
    </LoadingProvider>
  </CompanyProvider>
);

export default App;
